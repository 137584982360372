import React, { useEffect, useState, useRef } from 'react';
import { Fade, makeStyles } from '@material-ui/core';

const TRANSITION_TIME_MS = 1100;

interface StyleProps {
  isTransitioning: boolean;
};

const useStyles = makeStyles( {
  root: {
    transition: `opacity ${TRANSITION_TIME_MS/2}ms`,
    opacity: ( { isTransitioning }: StyleProps ) => isTransitioning ? 0 : 1,
  },
} );

interface TextSwitcherProps {
  texts: string[];
  time?: number;
};

export const TextSwitcher: React.FC<TextSwitcherProps> = ( props ) => {
  const [ index, setIndex ] = useState( 0 );
  const [ isTransitioning, setTransitioning ] = useState( false );

  const classes = useStyles( { isTransitioning } );

  useEffect( () => {
    const intervalId = setInterval( () => {
      setTransitioning( true );
      setTimeout( () => {
        setIndex( index => (index + 1) % props.texts.length );
        setTransitioning( false );
      }, TRANSITION_TIME_MS/2 );
    }, props.time );

    return () => clearInterval( intervalId );
  }, [ props.time ] );
  
  return <span className={ classes.root }>{ props.texts[ index ] }</span>;
};

TextSwitcher.defaultProps = {
  time: 5000,
};

export default TextSwitcher;