import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Favorite, Flare, TrendingUp, CloudQueue, AllInclusive } from '@material-ui/icons';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';

export interface BraggerProps {

};

interface StyleProps {
  isFirstRender: boolean;
  isShowingIcons: boolean;
}

const useStyles = makeStyles( theme => ({
  root: {
    margin: '24px auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'visible',
    gap: 30,
    [ theme.breakpoints.down( 850 ) ]: {
      justifyContent: 'space-around',
    },
  },
  container: {
    flex: '1 0 max(12%, 120px)',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 0,
    marginRight: 0,
  },
  bragbox: {
    width: '100%',
    background: '#f4f8fb',
    borderRadius: 12,
    padding: '0 28px 20px 28px',
    lineHeight: 0,
    fontSize: '1.4em',
    animation: '$enter 0.6s both paused',
    animationPlayState: (props: StyleProps) => props.isShowingIcons || props.isFirstRender ? 'running' : 'paused',
  },
  icon: {
    width: 54,
    height: 54,
    borderRadius: 27,
    border: '3px solid #fcfcfc',
    marginTop: -21,
    background: '#f4f8fb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: 36,
    },
  },
  '@keyframes enter': {
    '0%': {
      opacity: 0,
      transform: 'scale(0)',
      filter: 'grayscale(0.5)',
      animationTimingFunction: 'ease-in',
    },
    '50%': {
      transform: 'scale(0.8)',
      filter: 'grayscale(0.3) blur(0.2px)'
    },
    '82%': {
      transform: 'scale(1.28)',
      animationTimingFunction: 'ease-out',
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}) );

export const Bragger: React.FC<BraggerProps> = ( ) => {
  const [ isVisible, setVisible ] = useState( false );
  const [ isShowingIcons, setShowingIcons ] = useState( false );

  const isFirstRender = useIsFirstRender();
  const classes = useStyles( { isShowingIcons, isFirstRender });

  useEffect( () => {
    if ( isVisible ) {
      const timerId = window.setTimeout( () => setShowingIcons( true ), 190 );
      return () => clearTimeout( timerId );
    }
  }, [ isVisible ] );

  return (
    <VisibilitySensor partialVisibility={ true } active={ ! isShowingIcons } intervalDelay={ 25 } onChange={ ( isVisible ) => setVisible( isVisible ) }>
      <Box className={ classes.root }>
        <Box className={ classes.container }>
          <Box className={ classes.bragbox }>
            <Box className={ classes.icon }><Favorite style={{ color: '#e67' }}/></Box>
            <Box  my="4px"><Typography variant="subtitle2">Users&nbsp;First</Typography></Box>
            <Box>
              <Typography variant="body2" style={{ opacity: 0.8, fontSize: '0.8em'  }}>
                Beautiful, intuitive interfaces that are a pleasure to use.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={ classes.container }>
          <Box className={ classes.bragbox } style={{ animationDelay: '0.24s' }}>
            <Box className={ classes.icon }><TrendingUp style={{ color: '#5b3' }}/></Box>
            <Box my="4px"><Typography variant="subtitle2">Evolving</Typography></Box>
            <Box>
              <Typography variant="body2" style={{ opacity: 0.8, fontSize: '0.8em'  }}>
                Easy and affordable to change and extend.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={ classes.container }>
          <Box className={ classes.bragbox } style={{ animationDelay: '0.48s' }}>
            <Box className={ classes.icon }><Flare style={{ color: '#38d' }}/></Box>
            <Box my="4px"><Typography variant="subtitle2">Innovating</Typography></Box>
            <Box>
              <Typography variant="body2" style={{ opacity: 0.8, fontSize: '0.8em' }}>
              Features you didn't even know were possible
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={ classes.container }>
          <Box className={ classes.bragbox } style={{ animationDelay: '0.72s' }}>
            <Box className={ classes.icon }><CloudQueue style={{ color: '#97e' }}/></Box>
            <Box  my="4px"><Typography variant="subtitle2">Modern</Typography></Box>
            <Box>
              <Typography variant="body2" style={{ opacity: 0.8, fontSize: '0.8em'  }}>
                The latest frameworks and cloud-native best practices
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={ classes.container }>
          <Box className={ classes.bragbox } style={{ animationDelay: '0.96s' }}>
            <Box className={ classes.icon }><AllInclusive style={{ color: '#c60' }}/></Box>
            <Box my="4px"><Typography variant="subtitle2">Continuous</Typography></Box>
            <Box>
              <Typography variant="body2" style={{ opacity: 0.8, fontSize: '0.8em'  }}>
                Testing, deploying, monitoring, and scaling
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </VisibilitySensor>
  )
}

export default Bragger;