import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import TsParticles from 'react-tsparticles';
import { produce } from 'immer';
import useIsFirstRender from '../../hooks/useIsFirstRender';

export interface ParticlesProps {
  height: React.CSSProperties['height'];
  maxHeight: React.CSSProperties['maxHeight'];
}

export const Particles: React.FC<ParticlesProps> = props => {
  const { height, maxHeight } = props;

  const isFirstRender = useIsFirstRender();

  if ( isFirstRender )
    return null;

  return (
    <Box position="absolute" top={0} left={0} right={0} bottom={height} height={height} maxHeight={maxHeight} zIndex={0}>
      <TsParticles width="100%" height={height} options={ particleOptions } />
    </Box>
  );
};

const particleOptions = {
  autoPlay: true,
  fpsLimit: 50,
  resize: true,
  particles: {
    color: {
      value: '#001933',
      animation: {
        enable: true,
        speed: 160,
        sync: false,
      },
    },
    links: {
      enable: true,
      color: '#00458f',
      distance: 150,
      opacity: 0.17,
      width: 1,
    },
    move: {
      enable: true,
      warp: true,
    },
    number: {
      value: 10,
      density: {
        enable: true,
        area: 100,
        factor: 750,
      },
    },
    life: {
      delay: {
        random: {
          enable: true,
        },
        sync: false,
        value: 12,
      },
    },
    opacity: {
      value: 0.6,
    },
    size: {
      random: {
        enable: true,
        minimumValue: 0.4,
      },
      value: 2,
    },
  },
  interactivity: {
    detectsOn: 'canvas',
    resize: true,
    events: {
      onHover: {
        enable: true,
        mode: 'grab',
      },
    },
    modes: {
      grab: {
        distance: 120,
        links: {
          opacity: 0.21,
        },
      },
    },
  },
};
