import React from 'react';
import { Parallax } from 'react-parallax';
import { Box, Typography } from '@material-ui/core';

export interface BgParallaxProps {
  children: string;
  src: string;
  style?: React.CSSProperties;
}

export const BgParallax: React.FC<BgParallaxProps> = ( props ) => {
  const { children, src, style } = props;

  return (
    <Parallax style={ style } bgImage={ src } bgStyle={{ objectFit: 'cover' }} strength={420}>
      <Box height={300} display="flex" justifyContent="center" alignItems="center" bgcolor="#00000050">
        <Typography variant="h2" style={{ color: '#fff', fontWeight: 400, textShadow: '#00000090 -2px 0px 4px, #00000090 0px -2px 4px, #00000090 2px 0px 4px, #00000090 0px 2px 4px' }}>
          { children }
        </Typography>
      </Box>
    </Parallax>
  );
}