import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Collapse, Select as MuiSelect, MenuItem, makeStyles, SelectProps, TextFieldProps, RadioGroup, FormControlLabel, Radio, FormLabel, FormControl, Typography, CircularProgress } from '@material-ui/core';
import { Form, Formik, FormikConfig, useField } from 'formik';
import { CheckCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const METHOD = 'POST';
const ACTION = 'https://formspree.io/xrgyvwre';

interface ContactFormProps {
  open?: boolean;
  onOpen?: () => void;
  ref?: React.Ref<HTMLFormElement>;
};

class ContactFormData {
  name: string = '';
  subject: 'general' | 'development' | 'consulting' | 'training' | '' = '';
  contactMethod: 'phone' | 'email' | '' = '';
  message: string = '';
  email: string = '';
  phone: string = '';
};

type status = 'SUCCESS' | 'ERROR' | 'PENDING';

export const ContactForm: React.FC<ContactFormProps> = React.forwardRef( ( props, ref ) => {
  const [ isOpen, setIsOpen ] = useState( !! props.open );
  const [ status, setStatus ] = useState<status>( );

  useEffect( () => {
    if( open != null )
      setIsOpen( props.open );
  }, [ props.open ] );

  const handleSubmit: FormikConfig<FormData>['onSubmit'] = ( values, helpers ) => {
    const xhr = new XMLHttpRequest();

    xhr.open( METHOD, ACTION );
    xhr.setRequestHeader( 'Accept', 'application/json' );
    xhr.onreadystatechange = () => {

      if ( xhr.readyState !== XMLHttpRequest.DONE )
        return;
      if (xhr.status === 200) {
        setStatus( 'SUCCESS' );
      } else {
        setStatus( 'ERROR' );
      }

      helpers.setSubmitting( false );
    };

    const data = new FormData( );
    Object.keys( values ).forEach( key => data.append( key, values[key] ) );
    xhr.send( data );
  };

  const validateForm = ( values ) => {
    const errors: Record<string, string> = {};

    if ( ! values.name )
      errors.name = 'Name is required';

    if ( ! values.message )
      errors.message = 'Please include a message';

    const { contactMethod } = values;

    if ( ! contactMethod )
      errors.contactMethod = 'Please choose a contact method';
    else if ( ! values[ contactMethod ] )
      errors[ contactMethod ] = `${ contactMethod[0].toUpperCase() + contactMethod.slice(1) } is required`;
  
    return errors;
  };

  if ( status === 'SUCCESS' ) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <CheckCircle color="primary" style={{ margin: '24px 0', fontSize: '4.8rem' }} />
        <Typography>Thank you! We&rsquo;ll get back to you soon.</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box pb={1} textAlign="center">
        <Typography>Feel free to drop us a message for any reason at all.</Typography>
      </Box>
      <Formik initialValues={ new ContactFormData }  onSubmit={ handleSubmit } validate={ validateForm }>
        {
          ({ values, touched, errors, handleChange, handleBlur, isSubmitting }) => (
            <Form method={ METHOD } action={ ACTION }>
              {
                ! isOpen 
                ?
                  <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                    <Input name="name" label="Your name" style={{ flex: '0 1', margin: 0, marginRight: 24 }} />
                    <Button style={{ flex: '0 1 120px', marginLeft: 16 }} color="primary" variant="contained" onClick={ () => setIsOpen( true ) }>{'Contact\u00a0Us'}</Button>
                  </Box>
                :
                  <Box flex={1} display="flex" flexDirection="column" alignItems="center">
                    <Collapse style={{ width: '100%' }} in={isOpen} appear={false} timeout={400} onEntered={ () => { props.onOpen?.(); } }>
                      <Box>
                        <Input name="name" label="Your name" />
                        <Select name="subject" placeholder="Subject">
                          <MenuItem value="" disabled>Subject</MenuItem>
                          <MenuItem value="general">General</MenuItem>
                          <MenuItem value="development">Development Inquiry</MenuItem>
                          <MenuItem value="consulting">Consulting Inquiry</MenuItem>
                          <MenuItem value="training">Training Inquiry</MenuItem>
                        </Select>
                        <Input name="message" label="Your message" multiline={true} rows={3} />
                        <FormControl component="fieldset" error={ touched.contactMethod && !! errors.contactMethod }>
                          <FormLabel component="legend">Preferred Contact Method</FormLabel>
                          <RadioGroup row aria-label="contact-method" name="contactMethod" value={ values.contactMethod } onChange={ handleChange } onBlur={ handleBlur }>
                            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                            <FormControlLabel value="email" control={<Radio />} label="Email" />
                          </RadioGroup>
                        </FormControl>
                        { values.contactMethod === 'email' && <Input name="email" label="Email address" /> }
                        { values.contactMethod === 'phone' && <Input name="phone" label="Phone #" /> }
                      </Box>
                    </Collapse>
                    {
                      status === 'ERROR' &&
                      <Box width="100%" py={1}>
                        <Alert severity="error">There was a problem submitting the form. Please try again later.</Alert>
                      </Box>
                    }
                    <Box>
                      <Button style={{ minWidth: 240 }} type="submit" color="primary" variant="contained" disabled={ isSubmitting }>
                        Send
                        { isSubmitting && <CircularProgress size="1em" style={{ position: 'absolute', marginLeft: '3em' }} /> }
                      </Button>
                    </Box>
                  </Box>
              }
            </Form>
          )
        }
      </Formik>
    </>
  );
} );

ContactForm.displayName = 'ContactForm';

export default ContactForm;

const useSelectStyles = makeStyles( {
  root: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    color: (props: { value: string }) => props.value === '' ? '#00000088' : '#000000dd',
  },
} );

const Select = ( props: SelectProps ) => {
  const [ field, { error, touched } ] = useField( props.name );
  const selectClasses = useSelectStyles( { value: field.value } );

  return (
    <Box>
      <MuiSelect
        variant="outlined"
        classes={ selectClasses }
        error={ touched && !! error }
        displayEmpty
        fullWidth
        { ...field }
        { ...props }
      />
    </Box>
  );
};

const Input = ( props: TextFieldProps ) => {
  const [ field, { error, touched } ] = useField( props.name );

  return (
    <Box>
      <TextField 
        size="small"
        margin="normal"
        variant="outlined"
        multiline={false}
        error={ touched && !! error }
        fullWidth
        { ...field }
        { ...props }
      />
    </Box>
  );
}